import API from "../../../api";
import { call, put } from "redux-saga/effects";
import {RESERVATIONS_INFO_FAIL, RESERVATIONS_INFO_SUCCESS} from "../../actions/reservationsActions";
import {GET_ROOMS} from "../../actions/roomsActions";

export function* reservationsInfo({ payload }) {
    try {
        const response = yield call(() => {
            return API.reservations.getReservations(payload);
        });

        if (response.status === 200) {
            yield put({
                type: RESERVATIONS_INFO_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: RESERVATIONS_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: RESERVATIONS_INFO_FAIL,
        });
    }
}

export function* reservationCreate(action) {
    const { payload, meta } = action;
    try {
        const response = yield call(() => {
            if (payload.userOrder) {
                return API.reservations.createReservationOrder(payload)
            } else {
                return API.reservations.createReservation(payload);
            }
        });

        if (response.status === 201) {
            yield put({
                type: GET_ROOMS,
                payload: {}
            });
            meta.onFinish(true);
        } else {
            meta.onFinish();
        }
    } catch (e) {
        console.log(e);
        meta.onFinish();
    }
}

export function* reservationChange(action) {
    try {
        const { payload, meta } = action;

        const response = yield call(() => {
            return API.reservations.changeReservation(payload);
        });

        if (response.status === 200) {
            yield put({
                type: GET_ROOMS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        }
    } catch (e) {
        console.log(e);
    }
}

export function* reservationPaymentChange(action) {
    try {
        const { payload, meta } = action;

        const response = yield call(() => {
            return API.reservations.changeReservationPayment(payload);
        });

        if (response.status === 201) {
            yield put({
                type: GET_ROOMS
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        }
    } catch (e) {
        console.log(e);
    }
}
