import React, {useEffect} from "react";
import Router from "./Router";
import "../assets/styles/custom/styles.scss";
import {localStorageWrapper} from "../helpers/storageFactory";
import api from "../api";
import {useDispatch} from "react-redux";
import {SET_USER} from "../redux/actions/accountActions";

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorageWrapper.getItem("accountToken")) {
            const getUser = async() => {
                const response = await api.auth.loginWithToken();
                if (response && response.data) {
                    dispatch({type: SET_USER, payload: response.data})
                }
            }
            getUser();
        }
    }, [dispatch])

    return (
        <div className="app">
            <Router />
        </div>
    );
}

export default App;
