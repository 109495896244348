import { useSelector } from "react-redux";
import React from "react";
import Notifications from "react-notification-system-redux";

const NotificationsStack = ({ children } : { children: any }) => {
    const notifications = useSelector((state: any) => state.notifications);

    const style = {
        NotificationItem: {
            DefaultStyle: {
                margin: "10px 5px 2px 1px",
            },
        },
    };

    return (
        <div>
            <Notifications notifications={notifications} style={style} />
            {children}
        </div>
    );
};

export default NotificationsStack;
