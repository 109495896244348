import config from "../config";
import handler from "./handler";
import axios from "axios";
import { localStorageWrapper } from '../../helpers/storageFactory'

const defaults = {
    debug: true,
    clear_api_host: config.api_host,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};

const getConfigData = (data, formData) => {
    const configData = {
        headers: { ...defaults.headers },
    };

    if (data) {
        // GET
        configData.headers["Content-Type"] = "text/plain";
        configData.params = data;
    }
    if (formData) {
        configData.headers["Content-Type"] = "multipart/form-data";
    }
    const accessToken = localStorageWrapper.getItem('accountToken');

    if (accessToken) {
        configData.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
    }

    return configData;
};

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => Promise.reject(error)
);

const getOnThen = (response) => {
    if (!response.data || response.data.error) {
        handler.errorHandler(response.data);
        return {
            success: false,
        };
    } else {
        return response;
    }
};

const getOnCatch = (err) => {
    console.log(err);
    handler.errorHandler(err);
    return err.response && err.response.data
        ? {
            errorData: err.response.data,
            success: false,
        }
        : {
            success: false,
        };
};

class HttpServices {
    static async get({ path, data = {} }) {
        const configData = getConfigData(data);

        return axios
            .get(defaults.clear_api_host + path, configData)
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error));
    }

    static async post({ path, data = {} }) {
        return axios
            .post(defaults.clear_api_host + path, data, getConfigData())
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error));
    }

    static async put({ path, data = {} }) {
        return axios
            .put(defaults.clear_api_host + path, data, getConfigData())
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error));
    }

    static formData({ path, data }) {
        return axios
            .post(defaults.clear_api_host + path, data, getConfigData(null, true))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    static async delete({ path }) {
        return axios
            .delete(defaults.clear_api_host + path, getConfigData())
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error));
    }
}

export default HttpServices;
