import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotificationsWrapper from "../components/NotificationsWrapper";

const Timeline = lazy(() => import(/* webpackChunkName: "timeline" */ "../containers/Timeline"));
const ResetPassword = lazy(() => import(/* webpackChunkName: "reset-password" */ "../containers/ResetPassword"));

const Router = () => (
    <Suspense fallback={""}>
        <NotificationsWrapper>
            <Routes>
                <Route path={"/"} element={<Timeline />} />,
                <Route path={"/reset-password"} element={<ResetPassword />} />,
            </Routes>
        </NotificationsWrapper>
    </Suspense>
);

export default Router;
