import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Application from './app/App'
import store from './redux/store'
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <Application />
        </BrowserRouter>
    </Provider>
);
