import HttpService from "../../system/HttpServices";

export default {
    getReservations: (data: object) => {
        return HttpService.get({ path: "/reservations", data });
    },
    createReservation: (data: object) => {
        return HttpService.post({ path: "/reservations", data });
    },
    createReservationOrder: (data: object) => {
        return HttpService.post({ path: "/reservations/createOrder", data });
    },
    changeReservation: (data: object) => {
        return HttpService.put({ path: "/reservations", data });
    },
    changeReservationPayment: (data: object) => {
        return HttpService.put({ path: "/reservations/payments", data });
    },
    cancelReservation: (id: number) => {
        return HttpService.delete({ path: "/reservations/" + id });
    }
};
