import API from "../../../api";
import { call, put } from "redux-saga/effects";
import {GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL} from "../../actions/usersActions";

export function* getUsers(action) {
    try {
        const response = yield call(() => {
            return API.users.getUsers();
        });

        if (response.status === 200) {
            yield put({
                type: GET_USERS_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: GET_USERS_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_USERS_FAIL,
        });
    }
}

export function* userCreate(action) {
    try {
        const { payload, meta } = action;
        const response = yield call(() => {
            return API.users.createUser(payload);
        });

        if (response.status === 201) {
            yield put({
                type: GET_USERS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}

export function* deleteUser(action) {
    try {
        const { payload, meta } = action;
        const response = yield call(() => {
            return API.users.deleteUser(payload);
        });

        if (response.status === 200) {
            yield put({
                type: GET_USERS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}
