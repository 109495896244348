import HttpService from "../../system/HttpServices";

export default {
    getUsers: (data: object) => {
        return HttpService.get({ path: "/users", data });
    },
    getUserById: (userId: number) => {
        return HttpService.get({ path: "/users/" + userId });
    },
    createUser: (data: object) => {
        return HttpService.post({ path: "/users", data });
    },
    updateUserById: (userId: number, data: object) => {
        return HttpService.put({ path: "/users/" + userId, data });
    },
    questionCreate: (data: object) => {
        return HttpService.post({ path: "/users/question", data });
    },
    deleteUser: (data: { userId: number }) => {
        return HttpService.delete({ path: "/users/" + data.userId });
    },
    convertUsers: (payload: any) => {
        return HttpService.formData({ path: "/users/convert", data: payload })
    },
};
