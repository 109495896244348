import {
    SET_USER,
} from "../actions/accountActions";
import { localStorageWrapper } from '../../helpers/storageFactory'

const initialState = {
    token: localStorageWrapper.getItem("accountToken"),
    accountInfo: null
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case SET_USER: {
            return {
                ...state,
                token: payload.token || state.token,
                accountInfo: payload.user
            };
        }

        default: {
            return state;
        }
    }
}
