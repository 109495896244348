import HttpService from "../../system/HttpServices";

export default {
    getRooms: (data: object) => {
        return HttpService.get({ path: "/rooms", data });
    },
    createRoom: (data: object) => {
        return HttpService.post({ path: "/rooms", data });
    },
    editRoom: (data: object) => {
        return HttpService.put({ path: "/rooms", data });
    },
    deleteRoom: (data: { roomId: number }) => {
        return HttpService.delete({ path: "/rooms/" + data.roomId });
    },
};
