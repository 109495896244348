import HttpService from "../../system/HttpServices";

export default {
    getQuestions: (data: object) => {
        return HttpService.get({ path: "/questions", data });
    },
    createQuestion: (data: object) => {
        return HttpService.post({ path: "/questions", data });
    },
};
