import {
    GET_USERS,
    GET_USERS_SUCCESS,
} from "../actions/usersActions";

const initialState = {
    items: [],
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_USERS: {
            return {
                ...state,
            };
        }

        case GET_USERS_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
