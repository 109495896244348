import API from "../../../api";
import { call, put } from "redux-saga/effects";
import {GET_ROOMS, GET_ROOMS_SUCCESS, GET_ROOMS_FAIL} from "../../actions/roomsActions";

export function* getRooms({ payload }) {
    try {
        const response = yield call(() => {
            return API.rooms.getRooms(payload);
        });

        if (response.status === 200) {
            yield put({
                type: GET_ROOMS_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: GET_ROOMS_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_ROOMS_FAIL,
        });
    }
}

export function* roomCreate(action) {
    try {
        const { payload, meta } = action;

        const response = yield call(() => {
            return API.rooms.createRoom(payload);
        });

        if (response.status === 201) {
            yield put({
                type: GET_ROOMS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}

export function * editRoom(action) {
    try {
        const { payload, meta } = action;

        const response = yield call(() => {
            return API.rooms.editRoom(payload);
        });

        if (response.status === 201) {
            yield put({
                type: GET_ROOMS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}

export function * deleteRoom(action) {
    try {
        const { payload, meta } = action;

        const response = yield call(() => {
            return API.rooms.deleteRoom(payload);
        });

        if (response.status === 200) {
            yield put({
                type: GET_ROOMS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}
