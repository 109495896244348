export const ROOM_CREATE = "ROOM_CREATE";
export const ROOM_CREATE_SUCCESS = "ROOM_CREATE_SUCCESS";

export const GET_ROOMS = "GET_ROOMS";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_FAIL = "GET_ROOMS_FAIL";

export const EDIT_ROOM = "EDIT_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";

export const SET_CURRENT_YEAR = "";
