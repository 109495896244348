import {
    GET_ROOMS,
    GET_ROOMS_SUCCESS,
    SET_CURRENT_YEAR
} from "../actions/roomsActions";

const initialState = {
    items: [],
    isLoading: true,
    currentStartYear: null
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_ROOMS: {
            const currentStartYear = payload ? payload.year : null;
            action.payload = currentStartYear ? {
                ...payload,
                startDate: currentStartYear + "-01-01",
                endDate: (currentStartYear + 3) + "-01-01"
            } : (state.currentStartYear ? {
                startDate: state.currentStartYear + "-01-01",
                endDate: (state.currentStartYear + 3) + "-01-01"
            } : {});

            return {
                ...state,
                currentStartYear: currentStartYear || state.currentStartYear
            };
        }

        case GET_ROOMS_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        }

        case SET_CURRENT_YEAR: {
            return {
                ...state,
                currentStartYear: payload.year
            }
        }

        default: {
            return state;
        }
    }
}
