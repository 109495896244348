import Auth from "./auth";
import Reservations from "./reservations";
import Rooms from "./rooms";
import Users from "./users";
import Questions from "./questions"

export default {
    auth: Auth,
    reservations: Reservations,
    rooms: Rooms,
    users: Users,
    questions: Questions
};
