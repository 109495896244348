import HttpService from "../../system/HttpServices";

export default {
    register: (data: object) => {
        return HttpService.post({ path: "/auth/register", data });
    },
    login: (data: object) => {
        return HttpService.get({ path: "/auth/login", data });
    },
    loginWithToken: () => {
        return HttpService.get({ path: "/account/info" });
    },
    reset: (data: object) => {
        return HttpService.post({ path: "/auth/reset", data });
    },
    resetConfirm: (data: object) => {
        return HttpService.post({ path: "/auth/resetConfirm", data });
    },
};
