export const RESERVATIONS_INFO = "RESERVATIONS_INFO";
export const RESERVATIONS_INFO_SUCCESS = "RESERVATIONS_INFO_SUCCESS";
export const RESERVATIONS_INFO_FAIL = "RESERVATIONS_INFO_FAIL";
export const RESERVATIONS_INFO_LOADED = "RESERVATIONS_INFO_LOADED";

export const RESERVATION_CREATE = "RESERVATION_CREATE";
export const RESERVATION_CREATE_SUCCESS = "RESERVATION_CREATE_SUCCESS";
export const RESERVATION_CHANGE = "RESERVATION_CHANGE";
export const RESERVATION_CHANGE_SUCCESS = "RESERVATION_CHANGE_SUCCESS";

export const RESERVATION_PAYMENT_CHANGE = "RESERVATION_PAYMENT_CHANGE";
