const config = {
    api_host_clear: "",
    api_host: "",
    pusher_key: ""
};
if (!process.env.APP_ENV || process.env.APP_ENV === "development") {
    config.api_host_clear = "https://timelinecoliving.com";
    config.api_host = config.api_host_clear + "/api/v1";
    config.pusher_key = "";
} else if (!process.env.APP_ENV || process.env.APP_ENV === "staging") {
    config.api_host_clear = "https://timelinecoliving.com";
    config.api_host = config.api_host_clear + "/api/v1";
    config.pusher_key = "";
} else {
    // Production
    config.api_host_clear = "https://timelinecoliving.com";
    config.api_host = config.api_host_clear + "/api/v1";
    config.pusher_key = "";
}

export default config;
