import API from "../../../api";
import { call, put } from "redux-saga/effects";
import {GET_QUESTIONS, GET_QUESTIONS_SUCCESS} from "../../actions/questionsActions";

export function* getQuestions(action) {
    try {
        const response = yield call(() => {
            return API.questions.getQuestions();
        });

        if (response.status === 200) {
            yield put({
                type: GET_QUESTIONS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* questionCreate(action) {
    try {
        const { payload, meta } = action;
        const response = yield call(() => {
            return API.questions.createQuestion(payload);
        });

        if (response.status === 201) {
            yield put({
                type: GET_QUESTIONS,
                payload: {}
            });
            if (meta && meta.onSuccess) meta.onSuccess();
        } else {

        }
    } catch (e) {
        console.log(e);
    }
}
