import {
    RESERVATIONS_INFO,
    RESERVATIONS_INFO_SUCCESS,
} from "../actions/reservationsActions";

const initialState = {
    items: [],
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case RESERVATIONS_INFO:
            return {
                ...state,
            };

        case RESERVATIONS_INFO_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };

        default:
            return state;
    }
}
