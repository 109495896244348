import { GET_QUESTIONS, GET_QUESTIONS_SUCCESS } from "../actions/questionsActions";

const initialState = {
    items: [],
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_QUESTIONS: {
            return {
                ...state,
            };
        }

        case GET_QUESTIONS_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
