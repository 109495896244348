import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";
import { reducer as notifications } from "react-notification-system-redux";
import saga from "../sagas";
import {
    reservationsReducer,
    roomsReducer,
    usersReducer,
    questionsReducer,
    accountReducer
} from "../reducers/index";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
    account: accountReducer,
    reservations: reservationsReducer,
    rooms: roomsReducer,
    users: usersReducer,
    questions: questionsReducer,
    notifications: notifications,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(saga);

export default store;
