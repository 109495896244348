import { takeEvery } from "redux-saga/effects";
import {RESERVATION_CREATE, RESERVATIONS_INFO, RESERVATION_CHANGE, RESERVATION_PAYMENT_CHANGE} from "../actions/reservationsActions";
import {GET_USERS, USER_CREATE, DELETE_USER} from "../actions/usersActions";
import {GET_QUESTIONS, QUESTION_CREATE} from "../actions/questionsActions";
import {GET_ROOMS, ROOM_CREATE, EDIT_ROOM, DELETE_ROOM} from "../actions/roomsActions";
import { reservationsInfo, reservationCreate, reservationChange, reservationPaymentChange } from "./reservations";
import { getUsers, userCreate, deleteUser } from "./users";
import { getQuestions, questionCreate } from "./questions";
import {getRooms, roomCreate, editRoom, deleteRoom} from "./rooms";

function * saga () {
    yield takeEvery(RESERVATIONS_INFO, reservationsInfo);
    yield takeEvery(RESERVATION_CREATE, reservationCreate);
    yield takeEvery(RESERVATION_CHANGE, reservationChange);
    yield takeEvery(RESERVATION_PAYMENT_CHANGE, reservationPaymentChange);
    yield takeEvery(GET_USERS, getUsers);
    yield takeEvery(USER_CREATE, userCreate);
    yield takeEvery(GET_QUESTIONS, getQuestions);
    yield takeEvery(QUESTION_CREATE, questionCreate);
    yield takeEvery(GET_ROOMS, getRooms);
    yield takeEvery(ROOM_CREATE, roomCreate);
    yield takeEvery(EDIT_ROOM, editRoom);
    yield takeEvery(DELETE_ROOM, deleteRoom);
    yield takeEvery(DELETE_USER, deleteUser);
}

export default saga;
