import store from "../../redux/store";
import Notifications from "react-notification-system-redux";

class Handler {
    static errorHandler(errData) {
        if (errData.response && errData.response.status) {
            const data = errData.response.data;
            const message = data && data.error ? data.error : (data.message || "");

            switch (errData.response.status) {
                case 400:
                    this.badRequest(message);
                    break;
                case 403:
                    this.forbidden(message);
                    break;
                case 404:
                    this.notFound(message);
                    break;
                case 422:
                    this.badRequest(message);
                    break;
                case 429:
                    this.forbidden(message);
                    break;
                default:
                    this.defaultError(message);
                    break;
            }
        } else {
            this.networkError();
        }
    }

    static badRequest(message) {
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: message.length ? message : "Something went wrong.",
                position: "tr",
                action: "",
            })
        );
    }

    static forbidden(message) {
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: message.length ? message : "Something went wrong.",
                position: "tr",
                action: "",
            })
        );
    }

    static notFound(message) {
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: message.length ? message : "Something went wrong.",
                position: "tr",
                action: "",
            })
        );
    }

    static defaultError() {
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: "Please contact support.",
                position: "tr",
                action: "",
            })
        );
    }

    static networkError() {
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: "Something went wrong.",
                position: "tr",
                action: "",
            })
        );
    }

    static typeError(error) {
        // if we will have more specific errors in the future, we could move it to separated service or show some concrete errors
        let message = "";
        if (error === "search") {
            message = "You can use up to 10 search terms at the same time.";
        }
        store.dispatch(
            Notifications.error({
                title: "Error",
                message: message,
                position: "tr",
                action: "",
            })
        );
    }
}

export default Handler;
